   <template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="35%"
      @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form">
      <el-form-item label="邮箱:">
        <el-input v-model="form.email" size="small" placeholder="请输入邮箱"/>
      </el-form-item>
      <el-form-item label="签名:">
        <el-input v-model="form.signature" size="small" placeholder="请输入签名"/>
      </el-form-item>
      <el-form-item label="密码:">
        <el-input v-model="form.password" size="small" placeholder="请输入邮箱密码"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">关 闭</el-button>
      <el-button size="small" type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "emailEdit",
  data() {
    return {
      title: '新增',
      form: {
        email: '',
        signature: '',
        password: '',
      },
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    showEdit(row) {
      if (!row) {
        this.title = '新增'
      } else {
        this.title = '修改'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      console.log(123123)
    },
  },
}
</script>

<style scoped>

</style>
