<template>
  <el-card class="box-card">
    <el-col :span="24">
      <el-button icon="el-icon-plus" type="primary" size="small" style="margin-bottom: 10px" @click="handleEdit">新增邮箱
      </el-button>
    </el-col>
    <el-table
        :data="list"
        style="width: 100%"
        border
        size="small"
    >
      <el-table-column
          align="left"
          prop="id"
          label="ID"
          width="100">
      </el-table-column>
      <el-table-column
          align="center"
          prop="email"
          label="邮箱">
      </el-table-column>
      <el-table-column
          align="center"
          prop="signature"
          label="签名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="password"
          label="密码">
      </el-table-column>
      <el-table-column align="center" label="操作" width="100">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <el-col :span="24">
      <h4 style="margin-top: -10px">新邮件推送设置</h4>
      <el-button icon="el-icon-plus" type="primary" size="small" style="margin-bottom: 10px">来件邮箱</el-button>
      <el-button icon="el-icon-plus" type="primary" size="small" style="margin-bottom: 10px">用户</el-button>
      <span style="margin-left: 10px">
        <el-input v-model="form.name" size="small" style="width:200px" placeholder="请输入关键字"/>
        <el-button icon="el-icon-search" type="primary" size="small"
                   style="margin-bottom: 10px; margin-left: 10px">搜索</el-button>
      </span>
      <el-table
          :data="list"
          style="width: 50%; margin-bottom: 10px"
          border
          size="small"
      >
        <el-table-column
            align="center"
            prop="email"
            label="邮箱">
        </el-table-column>
        <el-table-column
            align="center"
            prop="signature"
            label="签名">
        </el-table-column>
        <el-table-column
            align="center"
            prop="password"
            label="密码">
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template #default="{ row }">
            <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            <el-button type="text" @click="handleDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" size="small" style="margin-bottom: 10px">保存</el-button>
      <!--      <div style="width: 600px; border: 2px solid #E4E7ED; padding: 10px; box-sizing: border-box; margin-bottom: 10px">
      &lt;!&ndash;        <vue-seamless-scroll
                  :data="arr"
                  class="seamless-warp"
                  :class-option="classOption"
              >
                <table cellspacing="0" cellpadding="0">
                  <tr
                      v-for="(item, index) in arr"
                      :key="index"
                      style="border: 1px dashed #ffffff;width:100%"
                  >
                    <td class="tab_title" style="width:60px; text-align: center; line-height: 30px">{{ item.id }}</td>
                    <td class="tab_title text_name" style="line-height: 30px">{{ item.descr }}</td>
                    &lt;!&ndash;            <td class="tab_title">{{ item.name }}</td>
                                <td class="tab_title">{{ item.kind }}</td>
                                <td class="tab_title">{{ item.value }}</td>
                                <td class="tab_title">{{ item.result }}</td>
                                <td class="" style="width:30%">{{ item.time }}</td>&ndash;&gt;
                  </tr>
                </table>
              </vue-seamless-scroll>&ndash;&gt;
            </div>-->
    </el-col>
    <Edit ref="Edit" @fetch-data="fetchData"/>
    <!--    <modifyEdit ref="modifyEdit"/>-->
  </el-card>
</template>
<script>
// import vueSeamlessScroll from 'vue-seamless-scroll'
import Edit from './components/emailEdit'
import {apiDeleteCountry, apiGetAreaMsg} from '@/request/api'

export default {
  name: 'EmailSettings',
  components: {Edit},
  props: [],
  data() {
    return {
      selectRows: '',
      queryForm: {
        /*page: 1,
        pageSize: 10,*/
        name: '',
      },
      form: {},
      arr: [
        {id: 1, descr: '用户 < 123456@qq.com > 发来新邮件'},
        {id: 2, descr: '用户 < 23456@qq.com > 发来新邮件asdmfs;dkf四道口附近你是开放的乐山大佛上看到了能否顺利的泛滥的反馈山卡拉地方你收到两份'},
        {id: 3, descr: '用户 < 345667@qq.com > 发来新邮件'},
        {id: 4, descr: '用户 < 456789@qq.com > 发来新邮件'},
        {id: 5, descr: '用户 < 56789@qq.com > 发来新邮件'},
      ],
      list: [
        {id: 1, email: '123@qq.com', signature: '这是前面', password: '123456'},
        {id: 2, email: '123@qq.com', signature: '这是前面', password: '123456'},
        {id: 3, email: '123@qq.com', signature: '这是前面', password: '123456'},
      ],
    }
  },
  computed: {
    classOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 43, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000 // 单步运动停止的时间(默认值1000ms)
      };
    }
  },
  watch: {},
  created() {
    // this.fetchData()
  },
  mounted() {
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteCountry({id: row.id}).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      }
    },
    //获取区域无限极数据
    async fetchData() {
      this.listLoading = true
      apiGetAreaMsg(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
      this.listLoading = false
    },
  }
}

</script>
<style scoped>
.text_name {
  display: -webkit-box; /*弹性伸缩盒子*/
  -webkit-box-orient: vertical; /*子元素垂直排列*/
  -webkit-line-clamp: 1; /*显示的行数*/
  overflow: hidden; /*溢出部分隐藏*/
}

.seamless-warp {
  overflow: hidden;
}
</style>
